<!-- @format -->

<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="10" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="2">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="查询标题..." />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      bordered
      style="vertical-align: middle !important; text-align: center"
    >
      <template #cell(title)="data">
        <b-link :href="data.item.url | fileUrl" target="_blank" v-if="data.item.url">{{ data.item.title }}</b-link>
        <b-link v-else>暂无</b-link>
      </template>
      <template #cell(content)="data">
        <b-button @click="getInfo(data.item)">查看详情</b-button>
      </template>
    </b-table>
    <b-modal id="money-modal" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" centered title="文章内容详情" size="lg">
      <!--      <b-table :items="fenqianField" responsive :fields="fenqianFieldColumn" primary-key="id" show-empty empty-text="No matching records found" class="position-relative" />-->
      <WangEditor :value="a" v-model="a" />
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('money-modal')">关闭</b-button>
      </template>
    </b-modal>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BBadge, BLink } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import VipManagerModule from '@/views/vip_web/News/NewsManagerModule'
import axiosIns from '@/libs/axios'
import { getCurrentInstance, nextTick, reactive, ref } from '@vue/composition-api/dist/vue-composition-api'
import WangEditor from '@/views/GeneralManagement/WebManager/Article/WangEditor'
import useInvoicesList from './memberList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    WangEditor,
    BLink
  },
  filters: {
    fileUrl(value) {
      if (!/^(http:\/\/|https:\/\/)/.test(value)) {
        return `https://${value}`
      }
      return value
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'vip-module'
    const { proxy } = getCurrentInstance()
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, VipManagerModule)
    const fenqianField = reactive([])
    const fenqianFieldColumn = [
      { key: 'title', label: '文章标题' },
      { key: 'content', label: '内容详情' },
    ]
    const a = ref('')

    const getInfo = id => {
      // console.log(id)
      a.value = id.content
      proxy.$bvModal.show('money-modal')
      return

      axiosIns.get('/article/news', { params: { id } }).then(response => {
        if (response.code == 0) {
          proxy.$bvModal.show('money-modal')
          nextTick(() => {
            fenqianField.splice(0, fenqianField.length, ...response.data)
          })
        } else {
          proxy.$bvToast.toast(response.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
        }
      })
    }
    const statusOptions = ['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due']
    const setVipDetail = data => {
      data.gender = data.gender == '男' ? 1 : 2
      store.commit(`${INVOICE_APP_STORE_MODULE_NAME}/setVipDetail`, data)
    }
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      statusFilter,
      confirmDelete,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      setVipDetail,
      statusFilter,
      fenqianField,
      fenqianFieldColumn,
      refetchData,
      getInfo,
      statusOptions,
      confirmDelete,
      // avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      a,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
