/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import axios from 'axios'

export default function useInvoicesList(proxy) {
  const toast = useToast()
  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '#',
      thStyle: {
        width: '80px',
        textAlign: 'center',
      },
    },
    // {
    //   key: 'cate.title',
    //   label: '分类',
    //   thStyle: {
    //     width: '160px',
    //     textAlign: 'center',
    //   },
    // },
    { key: 'title', label: '文章标题' },
    // { key: 'url', label: '文章链接' },
    // { key: 'content', label: '内容详情' },
    {
      key: 'status',
      label: '状态',
      thStyle: {
        width: '100px',
        textAlign: 'center',
      },
    },
    {
      key: 'created_at',
      label: '创建时间',
      thStyle: {
        width: '240px',
        textAlign: 'center',
      },
    },
  ]
  const perPage = ref(100)(100)
  const pageSize = ref(100)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 20, 25]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })
  // 查询全部数据
  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('vip-module/fetchInvoices', {
        pageSize: pageSize.value,
        page: currentPage.value,
        title: searchQuery.value,
      })
      .then(response => {
        const { list, total, pageSize } = response.data
        callback(list)
        totalInvoices.value = total
      })
  }
  // 数据删除
  const confirmDelete = val => {
    store
      .dispatch('vip-module/deleteInvoices', val)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除成功',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    statusFilter,
    refetchData,
    confirmDelete,
  }
}
